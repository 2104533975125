const routes = [
    {
        path: "/customers/payments",
        component: () => import("../pages/Payments"),
        props: (route) => ({
            student_program_id: parseInt(route.query.student_program_id??0),
            registration_id: parseInt(route.query.registration_id??0),
            receipt_number: route.query.receipt_number??''
        }),
        meta: {
            isAuthenticated: true,
            permission: "customer_customerpayments"
        }
    },
    {
        path: "/customers/payments/student/:student_program_id",
        component: () => import("../pages/Payments"),
        props: (route) => ({
            receipt_number: route.query.receipt_number??''
        }),
        meta: {
            isAuthenticated: true,
            permission: "customer_studentpayments"
        }
    },
    {
        path: "/customers/payments/registration/:registration_id",
        component: () => import("../pages/Payments"),
        props: (route) => ({
            receipt_number: route.query.receipt_number??''
        }),
        meta: { isAuthenticated: true }
    },
    {
        path: "/customers/payments/certificate/:number",
        component: () => import("../pages/Certificate.vue"),
        meta: {
            isAuthenticated: true,
            permission: "customer_customerpayments"
        }
    },
    {
        path: "/customers/payments/view/student/:student_program_id",
        component: () => import("../pages/View"),
        meta: { isAuthenticated: true }
    },
    {
        path: "/customers/payments/view/registration/:registration_id",
        component: () => import("../pages/View"),
        meta: { isAuthenticated: true }
    },
    {
        path: "/student/payments/:student_program_id?",
        component: () => import("../pages/Student"),
        meta: { isAuthenticated: true }
    },
    {
        path: "/my/payments",
        component: () => import("../pages/Student"),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    }
];

export default routes;
