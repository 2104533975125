import Base from '@/plugins/Base';
import router from '@/plugins/Router'

const module = {
    namespaced: true,
    state: () => ({
        data: {},
        username: null,
        pin: null
    }),
    getters: {
        getData(state) {
            return state.data
        },
        getStatus(state){
            return state.data && state.data.status ? state.data.status:null;
        },
        getUsername(state) {
            return state.username
        },
        getPin(state) {
            return state.pin
        }
    },
    mutations: {
        setData(state, data) {
            state.data = data
            Base.LocalStorage.set('turkmer_application_data', state.data)
        },
        setStatus(state, status) {
            state.data.status = status;
            Base.LocalStorage.set('turkmer_application_data', state.data)
        },
        setUsername(state, username) {
            state.username = username
        },
        setPin(state, pin) {
            state.pin = pin
        },
        clearData(state) {
            state.data = {}
            state.username = null
            state.pin = null
        }
    },
    actions: {
        initData({commit}) {
            let data = Base.LocalStorage.get('turkmer_application_data');
            let username = Base.LocalStorage.get('turkmer_application_username');
            let pin = Base.LocalStorage.get('turkmer_application_pin');
            if (data && username && pin) {
                commit('setData', data)
                commit('setUsername', username)
                commit('setPin', pin)
            }
        },

        isLogin({state}) {

            let data = state.data;
            let username = state.username;
            let pin = state.pin;
            if (!data || !username || !pin) {
                router.push('/turkmer/login');
            }
        },

        isLoginGoIndex({state}) {

            let data = state.data;
            let username = state.username;
            let pin = state.pin;
            if (data && username && pin) {
                router.push('/turkmer');
            }
        },

        logout({commit}) {


            commit('clearData')
            Base.LocalStorage.remove('turkmer_application_data')
            Base.LocalStorage.remove('turkmer_application_username')
            Base.LocalStorage.remove('turkmer_application_pin')

            router.push('/turkmer/login');
        }
    }
}

export default module;
