import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/programs', config);
}

const getAllPublic = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/programs/public', config);
}
const getProgramOptions = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/programs/options', config);
}

const getGroupsPublic = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/program/groups/public', config);
}

const graduateGetAllPublic = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/graduate/application/programs', config);
}

const get = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/programs/' + code);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/programs/' + data.id, data);
    }
    return Api.post('/programs', data);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/programs/' + id, formData);
}

const del = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/programs/' + code);
}

const dowloandAsExcel = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/programs/excel-export',{...config,responseType:'arraybuffer'});
}

const updateDefinitions = async (formData, programCode) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/programs/ects-informations/' + programCode, formData);
}

export default {
    getAll,
    get,
    store,
    update,
    del,
    getAllPublic,
    getGroupsPublic,
    graduateGetAllPublic,
    dowloandAsExcel,
    getProgramOptions,
    updateDefinitions,

}
